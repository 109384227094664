import * as React from 'react';
import Layout from '../../../components/layout';
import DataboutTools from '../../../components/organisms/DataboutTools';
import { Header } from '../../../components/organisms/Header';
import NeedMore from '../../../components/organisms/NeedMore';
import NewestNews from '../../../components/organisms/NewestNews';
import PageContent from '../../../components/organisms/PageContent';
import SubpageAbout from '../../../components/organisms/SubpageAbout';
import { graphql } from 'gatsby';
import { AboutType } from '../transport';
import innertext from 'innertext';
import SEO from '../../../components/seo';
import carbonImage from "../../../images/index/carbon-footprint-index.svg";
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { getPostModel } from '../../../shared/wp-post-utils';
import { defaultLanguage } from '../../../../site-config';

const CarbonFootprint: React.FC<AboutType> = ({data, location}) => {
  const {t} = useTranslation();
  const {language} = useI18next();
  const LDAPHeaderUser = JSON.parse(data.parent.author.node.ldapUser)[0];

    return ( 
        <Layout>
			      <SEO title={data.parent.title} description={innertext(data.parent.content)}/>
            <Header 
                title={data.parent.title} 
                description={innertext(data.parent.content)}
                isBackgroundImage={false}
                headerImage={carbonImage}
                headerImageWidth={800}
				        isSignature={true}
                user={data.parent.author.node}
                signatureAuthor={LDAPHeaderUser.name}
                signaturePosition={LDAPHeaderUser.title}
                signatureDeskPhoneNumber={LDAPHeaderUser.telephoneNumber?.[0]}
                signaturePhoneNumber={LDAPHeaderUser.mobile?.[0]}
                signatureEmail={LDAPHeaderUser.mail}
                signatureCity={LDAPHeaderUser.physicalDeliveryOfficeName}
                signatureShareLink={location.href}
                firstHeaderNavLink="uslugi"
                secondHeaderNavLink="srodowisko"
                firstHeaderNavLinkName={t('services.title')}
                secondHeaderNavLinkName={t('services.environment')}
                SEOTitle={data.parent.title} 
                SEODescription={innertext(data.parent.content)}
            />
            <PageContent>
                <SubpageAbout data={data.children.nodes}/>
                {data.newestNews.nodes.length > 0 && language === defaultLanguage && <NewestNews posts={data.newestNews.nodes.map(getPostModel)}/>}
                <DataboutTools title="Databout.Tools" subTitle={t('toolsAd.subTitle')} description={t('toolsAd.description')} link="/tools"/>
                <NeedMore/>
            </PageContent>
        </Layout>
     );
}
 
export default CarbonFootprint;


export const pageQuery = graphql`
query($language: String!) {
  parent: wpPage(slug: {regex: "/carbon-footprint/"}, language: {slug: {eq: $language}}) {
		title
    content
    ...AuthorPage
  }
  children: allWpPage(filter: {wpParent: {node: {slug: {regex: "/carbon-footprin/"}}}, language: {slug: {eq: $language}}}, sort: {fields: menuOrder}) {
		nodes {
      title
      content
      ...AuthorPage
    }
  }
  newestNews: allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "News"}}}}, language: {slug: {eq: $language}}, tags: {nodes: {elemMatch: {name: {eq: "środowisko"}}}}}
    limit: 3
	) {
		nodes {
			...PageItems
		}
	}
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`