import * as React from 'react';
import SubpageAboutItem from '../molecules/SubpageAboutItem';
import styled from 'styled-components';
import ButtonLink from '../atoms/ButtonLink';
import { WpPost } from '../../shared/wp-post-utils';

type SubpageAboutProps = {
    data: WpPost[],
           
    paddingBottom?: number;
    buttonTitle?: string;
    buttonLink?: string;
}

const StyledSubpageAbout = styled.div<Pick<SubpageAboutProps, "paddingBottom">>`
    width: 80%;
    padding: ${({ theme }) => theme.paddingDesktopDouble}; 
    padding-top: 80px;
    padding-bottom: 0px;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-bottom: 0px;
        width: 80%;
        padding-top: 80px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: ${({ theme }) => theme.paddingLaptop}; 
        width: 90%;
        padding-top: 80px;
        padding-bottom: 0px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet};
        width: 100%;
        padding-top: 80px;
        padding-bottom: 0px;

    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
        width: 100%;
     }
`;

const StyledButtonLink = styled(ButtonLink)`
    width: 220px;
    margin: -100px 0px 100px 90px;

    @media (min-width: 1200px) and (max-width: 1600px) {
        margin: -100px 0px 100px 190px;
    }
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        margin: -100px 0px 100px 0px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        margin: -185px 0px 100px 0px;
     }

`;

const SubpageAbout: React.FC<SubpageAboutProps> = ({data, paddingBottom = 0, buttonTitle, buttonLink}) => {
    const caseStudy = data.map((item, index) => {

        const author = item.author?.node.ldapUser && JSON.parse(item.author.node.ldapUser)[0]
        const telephone = author?.telephoneNumber?.shift();
        const mobile = author?.mobile?.shift();


        return( 
            <SubpageAboutItem 
                key={index}
                title={item.title} 
                description={item.content}
                user={item.author?.node}
                signatureAuthor={author && author.name}
                signaturePosition={author && author.title} 
                signaturePhoneNumber={author && mobile}
                signatureDeskPhoneNumber={author && telephone}
                signatureEmail={author && author.mail} 
                signatureCity={author && author.physicalDeliveryOfficeName} 
            />)
        })

    return ( 
        <StyledSubpageAbout paddingBottom={paddingBottom}>
            {caseStudy}
            {buttonTitle && buttonLink && <StyledButtonLink to={`${buttonLink}/`} type="blue">{buttonTitle}</StyledButtonLink>}
        </StyledSubpageAbout>
     );
}
 
export default SubpageAbout;