import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { WpUser } from '../../shared/wp-user-utils';
import { SignatureHeader } from './SignatureHeader';
import { SignatureMobile } from './SignatureMobile';

type SubpageAboutItemProps = {
    title: string | ReactNode;
    description: string;
    user: WpUser;
    signatureAuthor?: string;
    signaturePosition?: string;
    signaturePhoneNumber?: string,
    signatureDeskPhoneNumber?: string,
    signatureEmail?: string,
    signatureCity?: string,
}


const StyledSubpageAboutItem = styled.div`
    padding: 0 0 80px 90px;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: 0 0 80px 190px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
       padding: 0 0 80px 90px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: 0 0 80px 0;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: 0 0 40px 0; 
     }
`;

const Title = styled.h2`
    margin: 0;
    line-height: 1.2;
    color: ${({ theme }) => theme.primaryColor};
    font-size: ${({ theme }) => theme.fontSize.h3}; 
    margin-bottom: 50px;

    @media (min-width: 1px) and (max-width: 767.98px) {
       font-size: 26px;
       line-height: 1.3;
    }
`;

const Description = styled.div`
    margin: 0;
    font-size: 18px;
    line-height: 1.6;
    font-weight: 300;
    font-family: Museo sans;

    @media (min-width: 1px) and (max-width: 767.98px) {
        font-size: 17px;
    }
`;

const StyledSignatureHeader = styled.div`
    padding-top: 0px;
`;
 
const SubpageAboutItem: React.FC<SubpageAboutItemProps> = ({
    title, 
    description,  
    user,
    signatureAuthor, 
    signaturePosition, 
    signaturePhoneNumber,
    signatureDeskPhoneNumber,
    signatureEmail,
    signatureCity, }) => {

    return ( 
        <StyledSubpageAboutItem>
            <Title>{title}</Title>
            <Description dangerouslySetInnerHTML={{ __html: description}}></Description>

            {user &&
            <StyledSignatureHeader>
                <SignatureHeader 
                    isStatic={true}
                    user={user}
                    author={signatureAuthor} 
                    phoneNumber={signaturePhoneNumber}
                    email={signatureEmail}
                />
                <SignatureMobile 
                    user={user}
                    author={signatureAuthor} 
                    phoneNumber={signaturePhoneNumber}
                    email={signatureEmail}
                />
            </StyledSignatureHeader>
            }
        </StyledSubpageAboutItem>
     );
}
 
export default SubpageAboutItem;